import React from 'react';
import { Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Link from "next/link";

type PropsType = {
  query?: { goal: string }
}
export default function GetStartedButton(props: PropsType) {
  const {query} = props;

  let hrefObj: { pathname: string, query?: { goal: string } } = {pathname: "/find-niw-strategy"}

  if (query) {
    hrefObj = {pathname: "/find-niw-strategy", query}
  }

  return <Link href={hrefObj}>
    <Button
      className='mt20'
      variant="contained"
      color="primary"
      endIcon={<ArrowForwardIcon/>}
    >
      Get Started
    </Button>
  </Link>
}
