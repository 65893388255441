import { EDITOR_CONTROLS_IDS } from './constants';
export const convertToEditor = (variant: string, content: string= '', listContent: string[] = []) => {
  if(variant.toUpperCase() === 'IMAGE') {
      return JSON.stringify({
          "blocks":
              [
                  {
                      "key":"4ehm5",
                      "text":"",
                      "type":"IMAGEUPLOAD",
                      "depth":0,
                      "inlineStyleRanges":[],
                      "entityRanges":[],
                      "data":{}
                  },
                  {"key":"ckaha","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],
          "entityMap":{}});
  }
  if(variant.toUpperCase() === 'unordered-list-item'.toUpperCase()) {
      const blocks = listContent.map((item) => {
          const block = {
              "text":item,
              "type": "unordered-list-item",
              "depth":0,
              "inlineStyleRanges":[],
              "entityRanges":[],
              "data":{}
          };
          return block;
      });
      const obj = Object.assign({ blocks:blocks,"entityMap":{}});
      return JSON.stringify(obj);
  }
  const data = Object.assign({}, {
    "blocks":[
        {
          "text": content,
          "type": variant.toUpperCase(),
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
        }],"entityMap":{}});
  return JSON.stringify(data)
};


export const applyActiveClass = (id: string) => {
    EDITOR_CONTROLS_IDS.map((item) => {
        const element = document.getElementById(item);
        if(element) {
            element.classList.remove("active")
        }
    });
    const element = document.getElementById(id);
    if(element) {
        element.classList.add("active");
    }
};
