import React from 'react';
import { createStyles, makeStyles } from "@material-ui/core";
import left from './img-oval-btm-left.svg';
import right from './img-oval-btm-right.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    ovalBtmLeft: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 0,
      [ theme.breakpoints.down(992) ]: {
        display:'none',
      },
    },
    ovalBtmRight: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 0,
      [ theme.breakpoints.down(992) ]: {
      display:'none',
      },
    },
  }),
);

export interface OwnProps {
  direction: 'left' | 'right'
}

export default function OvalBottom(props: OwnProps) {
  const classes = useStyles();
  // default set left direction values
  let ico = left;
  let cls = classes.ovalBtmLeft;
  let w = 263;
  let h = 182;

  // right direction values
  if (props.direction === 'right') {
    ico = right;
    w = 545;
    h = 338;
    cls = classes.ovalBtmRight;
  }

  return (
    <img className={cls} src={ico} width={w} height={h} alt={`Oval Bottom ${props.direction}`}/>
  );
}
