import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Button,
  Tooltip
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import TextEditor from '../Editor';
import AppContext from "../AppContext";
import axios from 'axios';
import { useAuth } from "../../lib/useAuth";
import { useRouter } from 'next/router';
import { externalServerUrl, showEditIconBlue } from "../../server/constants";
import { convertToEditor } from "../../utils/helper";
import { getRandomString } from "../../utils/commonUtils";

const useStyles = makeStyles(() =>
  createStyles({
    sectionFaq: {
      padding: '65px 0',
    },
    root: {
      width: '100%',
    },
  }),
);


function Faq(props: any) {
  const appContext: any = React.useContext(AppContext);
  const classes = useStyles();
  const router = useRouter();
  const {user} = useAuth();
  const isLoggedIn: any = user && user.isLoggedIn;

  const {
    faqTitle,
    commonQuestions,
    commonQuestionAnswers
  } = props;

  const handleAddQuestion = async () => {
    let pageObj = JSON.parse(appContext.content);
    const key = getRandomString();
    pageObj[ "commonQuestions" ] = (commonQuestions || []).concat([Object.assign({key: key}, JSON.parse(convertToEditor("body1", "Question?")))]);
    pageObj[ "commonQuestionAnswers" ] = (commonQuestionAnswers || []).concat([Object.assign({key: key}, JSON.parse(convertToEditor("body1", "Anwser.")))]);

    const payload = {
      id: appContext.id,
      content: JSON.stringify(pageObj),
      templateId: appContext.templateId,
      status: appContext.status
    };
    await axios.put(`${externalServerUrl}/api/pages`, payload);
    await router.replace(router.asPath);
  };

  const handleRemoveQuestion = async (idx: number) => {
    let pageObj = JSON.parse(appContext.content);
    pageObj.commonQuestions.splice(idx, 1);
    pageObj.commonQuestionAnswers.splice(idx, 1);
    const payload = {
      id: appContext.id,
      content: JSON.stringify(pageObj),
      templateId: appContext.templateId,
      status: appContext.status
    };
    await axios.put(`${externalServerUrl}/api/pages`, payload);
    await router.replace(router.asPath);
  };

  return (
    <div className={classes.sectionFaq}>
      <Container maxWidth="lg">
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>

            <TextEditor color="primary" align="center"
                        content={faqTitle} sectionId={"faqTitle"}
                        upperDivClass={showEditIconBlue}
            />
            {isLoggedIn &&
              <Tooltip title="Add Questions" placement="bottom-start">
                <Button
                  size="small"
                  className="floatRight"
                  variant="contained"
                  color="primary"
                  onClick={handleAddQuestion}
                >
                  ADD
                </Button>
              </Tooltip>
            }
          </Grid>
          <Grid item xs={12}>
            <div className={classes.root}>
              {
                (commonQuestions || []).map((item: any, idx: number) =>
                  <Accordion key={item.key} className="iconChange boxShadowNone">
                    <AccordionSummary
                      id={`panel1a-header-${idx}`}
                      aria-controls={`panel1a-content-${idx}`}
                      className="accordionWrap"
                      expandIcon={<AddIcon className="AddIcon"/>}
                    >

                      <TextEditor color="primary"
                                  sectionId={`commonQuestion_${idx}`}
                                  content={item}
                                  upperDivClass={showEditIconBlue}
                      />

                      {
                        isLoggedIn &&
                        <Tooltip title="Delete Questions" placement="bottom-start">
                          <DeleteIcon className="deleteIcon" onClick={() => handleRemoveQuestion(idx)}/>
                        </Tooltip>
                      }
                    </AccordionSummary>
                    <AccordionDetails>

                      <TextEditor
                        className="w100 floatLeft"
                        sectionId={`commonQuestionAnswers_${idx}`}
                        content={commonQuestionAnswers[ idx ]}
                        upperDivClass={showEditIconBlue}
                      />

                    </AccordionDetails>
                  </Accordion>
                )
              }
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Faq;
