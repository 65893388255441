import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';

import TextEditor from '../Editor';
import { showEditIconBlue } from "../../server/constants";
import ovalsHomepage from '../../public/images/img-ovals-homepage-1.png';
import './style.module.css';

const useStyles = makeStyles((theme) =>
  createStyles({
    sectionAbout: {
      padding: '12% 0 8%',
      background: "url('" + ovalsHomepage + "')",
      backgroundRepeat: "no-repeat",
      backgroundPosition: 'top center',
      marginBottom: 0,
      alignItems: 'center',
      width: '100%',
      [ theme.breakpoints.down("lg") ]: {
        padding: '20% 0 5%',
      },
      [ theme.breakpoints.down("sm") ]: {
        backgroundSize: 'auto',
        padding: '30% 0 5%',
      },

      [ theme.breakpoints.down(767) ]: {
        padding: '40% 0 5%',
      },
    },
  }),
);

type Props = {
  aboutText1: any,
  aboutText2: any
}

export default function AboutContent(props: Props) {
  const classes = useStyles();
  const {aboutText1, aboutText2} = props;
  return (
    <div className={classes.sectionAbout}>
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={10}>
            <TextEditor
              className="mb40"
              align="center"
              color="primary"
              content={aboutText1}
              sectionId="aboutText1"
              upperDivClass={showEditIconBlue}
            />
            <TextEditor
              align="center"
              color="primary"
              content={aboutText2}
              sectionId="aboutText2"
              upperDivClass={showEditIconBlue}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
