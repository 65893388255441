import React from "react";
import { Button, Container, Grid } from "@material-ui/core";
import TextEditor from "../Editor";
import { convertToEditor } from "../../utils/helper";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { bucketURL, showEditIconBlue } from "../../server/constants";
import Link from "next/link";
import OvalBottom from "../OvalBottom";

type Props = {
  planningOneHeading: any,
  planningOneSubHeading: any,
  planningOneDescendantText: any,
  planningTwoHeading: any,
  planningTwoSubHeading: any,
  planningTwoDescendantText: any,
  planningThreeHeading: any,
  planningThreeSubHeading: any,
  planningThreeDescendantText: any,
  imgEmployeehandshake: any,
  imgPeopleonpatio: any,
  imgPeopleincar: any
}
export default function Plans(props: Props) {
  const {
    planningOneHeading,
    planningOneSubHeading,
    planningOneDescendantText,
    planningTwoHeading,
    planningTwoSubHeading,
    planningTwoDescendantText,
    planningThreeHeading,
    planningThreeSubHeading,
    planningThreeDescendantText,
    imgEmployeehandshake,
    imgPeopleonpatio,
    imgPeopleincar
  } = props;
  return (
    <>
      <div style={{position: 'relative',background:'#ffffff'}}>

        <div className="sectionPD">
          <Container maxWidth="lg" className="pdXsScreenNone">
            <Grid container spacing={5} justifyContent="center" alignItems="center" className="colReverse colMarginNone ">
              <Grid item xs={12} md={6} className="pdXsScreen20">
                <TextEditor
                  color="primary"
                  className="title"
                  content={planningOneHeading}
                  sectionId={"planningOneHeading"}
                  upperDivClass={showEditIconBlue}
                />

                <TextEditor color="primary" sectionId={"planningOneSubHeading"}
                            content={planningOneSubHeading}
                            upperDivClass={showEditIconBlue}
                />
                <TextEditor
                  className="w100 floatLeft"
                  sectionId={"planningOneDescendantText"}
                  content={planningOneDescendantText}
                  upperDivClass={showEditIconBlue}
                />
                <Link href="/estate-planning">
                  <Button
                    size="small"
                    className="mt20 floatLeft"
                    variant="outlined"
                    color="primary"
                    endIcon={<ArrowForwardIcon/>}
                  >
                    View Strategies
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} md={6} className="pdXsScreenNone pdXsScreenBottom">
                <TextEditor sectionId={"imgPeopleonpatio"} src={`${bucketURL}/${imgPeopleonpatio}`}
                            content={convertToEditor("Image")}
                            className="w100 positionR h100"
                            upperDivClass={showEditIconBlue}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
        <OvalBottom direction="right"/>
      </div>
      <div className="colorBg whiteBgXs pdXsScreenTop0">
        <Container maxWidth="lg" className="pdXsScreenNone">
          <Grid container spacing={5} justifyContent="center"
                alignItems="center" className="colMarginNone">

            <Grid item xs={12} md={6} className="pdXsScreenNone pdXsScreenBottom">
              <TextEditor content={convertToEditor("Image")}
                          src={`${bucketURL}/${imgPeopleincar}`} sectionId={"imgPeopleincar"}
                          className="w100 positionR h100"
                          upperDivClass={showEditIconBlue}
              />
            </Grid>
            <Grid item xs={12} md={6} className="pdXsScreen20">
              <TextEditor color="primary" className="title"
                          content={planningTwoHeading}
                          sectionId={"planningTwoHeading"}
                          upperDivClass={showEditIconBlue}
              />
              <TextEditor color="primary"
                          sectionId={"planningTwoSubHeading"}
                          content={planningTwoSubHeading}
                          upperDivClass={showEditIconBlue}
              />
              <TextEditor
                className="w100 floatLeft"
                sectionId={"planningTwoDescendantText"}
                content={planningTwoDescendantText}
                upperDivClass={showEditIconBlue}
              />
              <Link href="/retirement-planning">
                <Button
                  size="small"
                  className="mt20 floatLeft"
                  variant="outlined"
                  color="primary"
                  endIcon={<ArrowForwardIcon/>}
                >
                  View Strategies
                </Button>
              </Link>
            </Grid>
            <OvalBottom direction="left"/>
          </Grid>
        </Container>
      </div>
      <div style={{position: 'relative',background:'#ffffff'}} className="sectionPD pdXsScreenTop0">
        <Container maxWidth="lg" className="pdXsScreenNone">
          <Grid container spacing={5} justifyContent="center" alignItems="center" className="colReverse colMarginNone">
            <Grid item xs={12} md={6} className="pdXsScreen20">
              <TextEditor
                color="primary"
                className="title"
                sectionId={"planningThreeHeading"}
                content={planningThreeHeading}
                upperDivClass={showEditIconBlue}
              />
              <TextEditor
                color="primary"
                sectionId={"planningThreeSubHeading"}
                content={planningThreeSubHeading}
                upperDivClass={showEditIconBlue}
              />
              <TextEditor
                className="w100 floatLeft"
                sectionId={"planningThreeDescendantText"}
                content={planningThreeDescendantText}
                upperDivClass={showEditIconBlue}
              />
              <Link href="/business-planning">
                <Button
                  size="small"
                  className="mt20 floatLeft"
                  variant="outlined"
                  color="primary"
                  endIcon={<ArrowForwardIcon/>}
                >
                  View Strategies
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12} md={6} className="pdXsScreenNone pdXsScreenBottom">
              <TextEditor content={convertToEditor("Image")}
                          src={`${bucketURL}/${imgEmployeehandshake}`}
                          sectionId={"imgEmployeehandshake"}
                          className="w100 positionR h100"
                          upperDivClass={showEditIconBlue}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
