import React from 'react';
import { Card, CardContent, Container, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player/lazy';
import dynamic from 'next/dynamic';

import { showEditIconBlue, showEditIconWhite, videoBucketUrl } from '../../server/constants';
import GetStartedButton from '../Button/GetStartedButton';

const TextEditor = dynamic(() => import('../Editor'));
const BottomSection = dynamic(() => import('./BottomSection'));

const useStyles = makeStyles((theme) =>
  createStyles({
    mainWrap: {
      position: 'relative',
      top: '60px',

      [ theme.breakpoints.up(2000) ]: {
        overflow: 'visible',
      },


      [ theme.breakpoints.down(1500) ]: {
        top: 50,
      },

      [ theme.breakpoints.down('sm') ]: {
        overflow: 'auto',
        width: '100%',
        top: 15,
      },

      [ theme.breakpoints.down('xs') ]: {
        overflow: 'unset',
        top: 0,
      },
    },

    title: {
      paddingBottom: theme.spacing(4),
    },
  }),
);


type Props = {
  isIntroVideoComplete: boolean,
  slogan: any,
  strategyText: any,
  strategyDescendantText: any,
  requirementSubHeading1: any,
  requirementHeading1: any,
  requirementHeading2: any,
  requirementSubHeading2: any,
  requirementHeading3: any,
  requirementSubHeading3: any,
  setIsIntroVideoComplete: Function
}

export default function Hero(props: Props) {
  const classes = useStyles();
  const {
    isIntroVideoComplete,
    slogan,
    strategyText,
    strategyDescendantText,
    requirementSubHeading1,
    requirementHeading1,
    requirementHeading2,
    requirementSubHeading2,
    requirementHeading3,
    requirementSubHeading3,
    setIsIntroVideoComplete,
  } = props;

  const [videoUrl, setVideoUrl] = React.useState(`${videoBucketUrl}/brand-intro-video.mp4`);
  const [hideIntroVideoWithDelay, setHideIntroVideoWithDelay] = React.useState(false);

  return (
    <div className="video-container">
      <ReactPlayer
        playing={true}
        playsinline
        controls={false}
        volume={0}
        muted={false}
        loop={isIntroVideoComplete}
        url={videoUrl}
        aspectRatio='auto'
        width='100vw'
        height='90vh'
        className={isIntroVideoComplete ? "VideosHeight secondVideos" : "VideosHeight FirstVideos "}
            onEnded={() => {
              setIsIntroVideoComplete(true);

              setTimeout(() => {
                setHideIntroVideoWithDelay(true);
              } ,200 )

              setIsIntroVideoComplete(true);

              setVideoUrl(`${videoBucketUrl}/hero-video.mp4`);
            }
        }
      />
      {
        hideIntroVideoWithDelay &&
        <div className="video-mask">
          <Container maxWidth="lg" className="slide">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} md={8}>
                <TextEditor
                  className=" textWhite title"
                  content={slogan}
                  sectionId="slogan"
                  upperDivClass={showEditIconWhite}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card className="getStarted">
                  <CardContent className="getStartedContent">
                    <TextEditor component="strong" color="primary"
                                content={strategyText}
                                sectionId="strategyText"
                                upperDivClass={showEditIconBlue}
                    />
                    <TextEditor
                      className="mt15"
                      content={strategyDescendantText}
                      sectionId="strategyDescendantText"
                      upperDivClass={showEditIconBlue}
                    />
                    <GetStartedButton/>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.mainWrap}>
                  <BottomSection requirementHeading1={requirementHeading1}
                                 requirementSubHeading1={requirementSubHeading1}
                                 requirementHeading2={requirementHeading2}
                                 requirementSubHeading2={requirementSubHeading2}
                                 requirementHeading3={requirementHeading3}
                                 requirementSubHeading3={requirementSubHeading3}
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      }
    </div>
  );
}
