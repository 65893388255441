import React from "react";
import { GetServerSideProps } from "next";

import Layout from "../components/Layout";
import AppContext from "../components/AppContext";
import fetchData from "../lib/fetchData";

import AboutContent from "../components/AboutContent";
import Hero from "../components/Hero/Hero";
import Plans from "../components/Plans";
import FindAgent from "../components/FindAgent";
import Faq from "../components/Faq";
import CarouselEditor from "../components/Editor/CarouselEditor";
import CarrierLogos from "../components/AboutContent/CarrierLogos";


export const getServerSideProps: GetServerSideProps = fetchData('/api/pages/home');

function IndexPage(props: any) {
  const [isIntroVideoComplete, setIsIntroVideoComplete] = React.useState<boolean>(false);

  const {
    slogan,
    strategyText,
    strategyDescendantText,
    aboutText1,
    aboutText2,
    planningOneHeading,
    planningOneSubHeading,
    planningOneDescendantText,
    planningTwoHeading,
    planningTwoSubHeading,
    planningTwoDescendantText,
    planningThreeHeading,
    planningThreeSubHeading,
    planningThreeDescendantText,
    findAgentTitle,
    findAgentSubTitle,
    findAgentByZipTitle,
    requirementHeading1,
    requirementSubHeading1,
    requirementHeading2,
    requirementSubHeading2,
    requirementHeading3,
    requirementSubHeading3,
    faqTitle,
    commonQuestions,
    commonQuestionAnswers,
    imgEmployeehandshake,
    imgPeopleonpatio,
    imgPeopleincar,
    carouselId
  } = props.data;

  return (
    <AppContext.Provider value={props.page}>
      <Layout
        indexPage
        title={props.title}
        isIntroVideoComplete={isIntroVideoComplete}
        keyword={props.keyword}
        pageDescription={props.description}
        homePage={true}
      >
        <Hero
          slogan={slogan}
          strategyDescendantText={strategyDescendantText}
          strategyText={strategyText}
          requirementHeading1={requirementHeading1}
          requirementSubHeading1={requirementSubHeading1}
          requirementHeading2={requirementHeading2}
          requirementSubHeading2={requirementSubHeading2}
          requirementHeading3={requirementHeading3}
          requirementSubHeading3={requirementSubHeading3}
          setIsIntroVideoComplete={setIsIntroVideoComplete}
          isIntroVideoComplete={isIntroVideoComplete}
        />
        <Plans
          planningOneHeading={planningOneHeading}
          planningOneSubHeading={planningOneSubHeading}
          planningOneDescendantText={planningOneDescendantText}
          planningTwoHeading={planningTwoHeading}
          planningTwoSubHeading={planningTwoSubHeading}
          planningTwoDescendantText={planningTwoDescendantText}
          planningThreeHeading={planningThreeHeading}
          planningThreeSubHeading={planningThreeSubHeading}
          planningThreeDescendantText={planningThreeDescendantText}
          imgEmployeehandshake={imgEmployeehandshake}
          imgPeopleonpatio={imgPeopleonpatio}
          imgPeopleincar={imgPeopleincar}
        />
        <AboutContent aboutText1={aboutText1} aboutText2={aboutText2}/>
        <CarrierLogos/>
        <div className="mb40">
          <CarouselEditor carouselId={carouselId}/>
        </div>
        <FindAgent
          findAgentTitle={findAgentTitle}
          findAgentSubTitle={findAgentSubTitle}
          findAgentByZipTitle={findAgentByZipTitle}
        />
        <Faq
          faqTitle={faqTitle}
          commonQuestions={commonQuestions}
          commonQuestionAnswers={commonQuestionAnswers}
        />
      </Layout>
    </AppContext.Provider>
  )
}

export default IndexPage;
