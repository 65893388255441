import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid/Grid";
import { List, ListItem, Container } from "@material-ui/core";
import Link from "next/link";

import './style.module.css';
import AllianzLogo from '../../public/images/AllianzLogo.jpg';
import Img4 from '../../public/images/NationalLifeLogo.jpg';
import Img5 from '../../public/images/logo-paclife-front.jpg';
import Img6 from '../../public/images/securian-logo.jpg';

const carriers = [
  {img: AllianzLogo, alt: 'Allianz', w: 244, h: 120},
  {img: Img4, alt: 'National Life', w: 244, h: 120},
  {img: Img5, alt: 'Pacific Life', w: 244, h: 120},
  {img: Img6, alt: 'securian', w: 244, h: 124},
];

const useStyles = makeStyles((theme) =>
  createStyles({
    sectionAbout: {
      padding: '0% 0 5%',
      marginBottom: 30,
      alignItems: 'center',
      width: '100%',
    },
    partnerIcons: {
      display: "flex",
      [ theme.breakpoints.down("md") ]: {
        display: "inline !important",
      },
    },
    partnerIcon: {
      width: "100% !important",
      padding: 0,
      marginRight: 10,
      [ theme.breakpoints.down("md") ]: {
        width: "30% !important",
        float: 'left'
      },
      [ theme.breakpoints.down(768) ]: {
        width: "45% !important",
      },
    },
    partnerLogo: {
      width: '90%',
      [ theme.breakpoints.down("md") ]: {
        width: 'auto',
      },
      [ theme.breakpoints.down("sm") ]: {
        width: '100%',
        height: 'auto',
      },
    },

  }),
);

function CarrierLogos() {
  const classes = useStyles();
  return (
    <div className={classes.sectionAbout}>
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <List className={classes.partnerIcons + ' ' + " LogoList "}>
              {
                carriers.map((o, idx) => (
                  <ListItem key={idx} className={classes.partnerIcon}>
                    <Link href="/">
                      <img
                        loading="lazy"
                        className={classes.partnerLogo}
                        src={o.img}
                        alt={o.alt}
                        width={o.w}
                        height={o.h}
                      />
                    </Link>
                  </ListItem>
                ))
              }
            </List>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default CarrierLogos;
